import { Container, Heading, Text, Box } from "@chakra-ui/react"
import React from "react"

const HeadingSubline = ({heading, subline, fontHeading, fontSubline, color, showLine}) => {
  return (
    <Container variant='layoutContainer'> 
      <Text color={color} textStyle='h1' as='h2' fontFamily={fontHeading} textAlign='center'>{heading}</Text>
      <Text color={color} textStyle='h3' as='h3' fontFamily={fontSubline} textAlign='center'>{subline}</Text>
     {showLine &&<Box w='100%' h='1px' bg={color} my={8}></Box>}
    </Container>
  )
}

export default HeadingSubline