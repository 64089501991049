import { Text } from '@chakra-ui/react'
import React from 'react'
import { baseTheme } from '../theme';
import RouterLink from '../componentLibrary/components/RouterLink'
import GImage from './GImage';

const IconBtn = ({ version, link, isExternal, text, borderColor, textColor, imgSrc, ...restProps }) => {
  return (
    <RouterLink activeStyle={{borderBottom: 'auto'}} textStyle='pBig' alignItems='center' _focus={{outline: '1px solid', outlineColor: {borderColor}, outlineOffset: '4px'}} link={link} isExternal={isExternal} display='flex' gap={8} {...restProps} py={3} px={3} border='1px solid' borderColor={borderColor} position='relative'>
      <GImage w='27px' h='27px'  src={imgSrc} alt='' />
    </RouterLink>
  )
}


export default IconBtn
