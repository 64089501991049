import { Text } from '@chakra-ui/react'
import React from 'react'
import { baseTheme } from '../theme';
import RouterLink from '../componentLibrary/components/RouterLink'

const Btn = ({ version, link, isExternal, text, borderColor, textColor, arrowColor, ...restProps }) => {
  return (
    <RouterLink activeStyle={{borderBottom: 'auto'}} textStyle='pBig' alignItems='center' _focus={{outline: '1px solid', outlineColor: {borderColor}, outlineOffset: '5px'}} link={link} isExternal={isExternal} display='flex' gap={8} {...restProps} py={3} px={8} border='1px solid' borderColor={borderColor}>
      <Text as='span' color={textColor}>{text}</Text>
      <svg width="56" height="23" viewBox="0 0 56 23" fill="none">
        <path d="M0 11.5H55M55 11.5L40.367 22M55 11.5L40.367 1" stroke={arrowColor ? arrowColor : baseTheme.colors.brand.primary} />
      </svg>
    </RouterLink>
  )
}


export default Btn
