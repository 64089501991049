import React, { useEffect } from "react"
import { Container, Text, Box, useColorMode, Heading } from "@chakra-ui/react";
import Text3Col from "../components/Text3Col";
import HeadingSubline from "../components/Heading+Subline";
import GImage from "../components/GImage";
import HeroSection from "../components/HeroSection";
import Layout from "../components/Layout/Layout";
import Feature2Col from "../components/Feature2Col";
import ContactBanner from "../components/ContactBanner";
import EPStage from "../components/EPStage";
import SongScroller from "../components/SongScroller";

const Music = () => {
  return (
    <Layout title='Musik' colorMode='dark'>
        <Box bg='brand.space' w='100%'>

          {/* <Container variant='layoutContainer' pt={{ sm: 24, base: 20 }} px={{ sm: 8, base: 4 }}>
            <HeroSection
              alt='Band Majanko vor einer Hafenmauer'
              bgColor='brand.sand'
              justifyContent='center'
              minH={{ md: '650px', base: '400px' }}
              maxH={{ md: '650px', base: '400px' }}
              video={false}
              mediaOpacity={1}
              mediaSrc='space_bg_gradient.jpg'
            >
              <Box display='grid' h='100%'>
                <GImage alignSelf='center' gridRow='1' gridColumn='1' src='planet_sun.png' w='300px' h='300px' mx='auto' objectFit='cover' zIndex={2} />
                <Box gridRow='1' gridColumn='1' alignSelf='end' h='400px' bg='red' zIndex={1} background={'linear-gradient(0deg, rgba(0,0,17,1) 0%, rgba(255,255,255,0) 100%)'}></Box>
              </Box>
            </HeroSection>
          </Container> */}

          <EPStage />

          {/* <Text as='h2' py={32} textAlign='center' textStyle='h1' color='brand.pearl'>Entdecke unsere Songs.</Text> */}
          {/* <SongScroller /> */}
          
          <Box bg='brand.space'>
            <ContactBanner 
              topline='Kontakt'
              heading='Ein Stück Weg miteinander gehen.'
              text='Sei es eine Anfrage für einen Auftritt oder Fanpost - wir freuen uns immer auf Nachrichten! Kontaktiere uns gerne über unsere Social-Media-Kanäle oder schicke uns Elektropost.'
              color='brand.pearl'
              btnText='E-Mail'
              btnBgColor='transparent'
              btnBorderColor={'brand.pearl'}
              btnTextColor='brand.pearl'
              iconBtnBgColor={'transparent'}
              iconBtnBorderColor={'brand.pearl'}
              iconBtnColor=''
              iconBtnSrc1='icon_insta_white.svg'
              iconBtnSrc2='icon_fb_white.svg'
              isExternal1={true}
              link1='mailto:majanko.music@gmail.com'
              isExternal2={true}
              link2='/https://instagram.com/majanko.music?igshid=YmMyMTA2M2Y='
              isExternal3={true}
              link3='/https://www.facebook.com/majanko.music'

            />
          </Box>

          

        </Box>
    </Layout>
  )
}

export default Music